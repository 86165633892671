import { Status } from 'types';

import { toCamelCase } from '../../../helpers/strings';
import { selectUserGeoInfoStatuses } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  UserGeoInfoSetStatusFetchUserGeoInfoAction,
  UserGeoInfoSetGeoInfoDataAction,
  UserGeoInfoData,
} from '../../types';

export const userGeoInfoSetStatusFetchUserGeoInfo = (
  fetchUserGeoInfo: Status,
): UserGeoInfoSetStatusFetchUserGeoInfoAction => ({
  type: REDUX_ACTION_TYPES.USER_GEO_INFO_SET_STATUS_FETCH_USER_GEO_INFO,
  fetchUserGeoInfo,
});

export const userGeoInfoSetGeoInfoData = (data: UserGeoInfoData): UserGeoInfoSetGeoInfoDataAction => ({
  type: REDUX_ACTION_TYPES.USER_GEO_INFO_SET_GEO_INFO_DATA,
  data,
});

export const userGeoInfoFetchGeoInfoDataHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    /**
     * This check is for preventing highly possible duplications
     * with UserInit dispatch after the Succeeded Login, or Interval in SIP, etc...
     */
    const { fetchUserGeoInfo: statusFetchUserGeoInfo } = selectUserGeoInfoStatuses(getState());
    if (statusFetchUserGeoInfo === Status.LOADING) {
      return;
    }

    const userGeoInfoData = {
      ip: '',
      countryCode: '',
      countryName: '',
      regionCode: '',
      regionName: '',
      city: '',
      zipCode: '',
      timeZone: '',
      latitude: 0,
      longitude: 0,
      metroCode: 0,
    };

    try {
      dispatch(userGeoInfoSetStatusFetchUserGeoInfo(Status.LOADING));

      const userGeoLocation = await services.userService.fetchUserGeoLocation();

      Object.keys(userGeoLocation).forEach((key: string) => {
        const keyInCamelCase = toCamelCase(key);
        if (userGeoLocation[key] && userGeoInfoData[keyInCamelCase] !== undefined) {
          userGeoInfoData[keyInCamelCase] = userGeoLocation[key];
        }
      });

      dispatch(userGeoInfoSetStatusFetchUserGeoInfo(Status.SUCCEEDED));
    } catch {
      dispatch(userGeoInfoSetStatusFetchUserGeoInfo(Status.FAILED));
    } finally {
      if (!userGeoInfoData.timeZone) {
        userGeoInfoData.timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/Paris';
      }

      dispatch(userGeoInfoSetGeoInfoData(userGeoInfoData));
    }
  };
